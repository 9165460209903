.benefits {
	display: flex;
	gap:$margin-default;
	padding: $inside-block-padding $inside-block-padding $default-padding $inside-block-padding;
	border-bottom: 1px solid $color-border-dark;

	position: relative;
	z-index: 10;
	@media screen and (max-width:768px) {
		padding: $inside-block-padding-mobile $inside-block-padding-mobile $default-padding-mobile $inside-block-padding-mobile;
		flex-direction: column;
	}
	.title {
		width: 40%;
		display: flex;
		flex-direction: column;
		gap:$inside-block-padding;
		justify-content: space-between;
		margin-bottom: $margin-default;
		h3 {
			margin-bottom: $margin-default-mobile;
			@media screen and (max-width:768px) {
				width: 100%;
				margin-bottom: $margin-default-mobile;
			}
		}
		@media screen and (max-width:768px) {
			width: 100%;
			margin-bottom: $margin-default-mobile;
		}
		.logos {
			display: flex;
			flex-wrap: wrap;
			gap:$inside-block-padding;
			&-item {
				width: calc(50% - calc($inside-block-padding / 2));
				padding: $inside-block-padding;
				border-radius: $border-radius;
				border: 1px solid $colordark-contrast;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 100%;
					@media (max-width:768px) {
						width: 60%;
					}
				}
			}
		}
	}
	.text {
		width: 60%;
		@media screen and (max-width:768px) {
			width: 100%;
		}
		.list-benefits {
			counter-reset: counter; 
			border-top: 1px solid $colordark-contrast;
			.item {
				padding:$inside-block-padding 0 $inside-block-padding 6vw;
				position: relative;
				font-size: 3vw;
				border-bottom: 1px solid $colordark-contrast;
				@media (max-width:768px) {
					font-size: 24px;
					padding:$inside-block-padding-mobile 0 $inside-block-padding-mobile 50px;

				}
				&::before {
					content: "0" counter(counter);
    				counter-increment: counter;

					color: $color-border-dark;

					font-family: $font-family-title;
					font-weight: bold;
					font-size: 3vw;

					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					@media (max-width:768px) {
						font-size: 24px;
					}
				}
			}
		}
	}
}