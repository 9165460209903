.slider-main-wrapper {
    width: 100%;
    position: relative;
    z-index: 10;
    overflow: hidden;
}
#slideContainer {
    padding-top: 60px;
	width: 400%; /* to contain 4 panels, each with 100% of window width */
	height: calc(100vh);
	overflow: hidden;
	-webkit-perspective: 1000;
			perspective: 1000;

    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
}
.panel {
	height: auto;
	width: 25%; /* relative to parent -> 25% of 400% = 100% of window width */
	float: left;
    position: relative;
    display: flex;
    align-items: center;
    padding: $inside-block-padding;
    @media (max-width:768px) {
        padding: $inside-block-padding-mobile;
    }
    .possibility-item {
        position: relative;
        display: flex;
        gap:$margin-default;
        // overflow: hidden;
        @media (max-width:768px) {
            flex-direction: column;
            align-items: start;
        }
        .img {
            width: 60%;
            min-width: 60%;
            // overflow: hidden;

            @media (max-width:768px) {
                width: 80%;
                min-width: 80%;
            }
            @media (max-width:575px) {
                width: 100%;
                min-width: 100%;
            }
        }
        img {
            width: 100%;
            display: block;
            border-radius: $border-radius;
        }
        h4 {
            text-transform: uppercase;
            margin-bottom: $margin-default;
        }
        .link {
            position: relative;
            display: flex;
            align-items: center;
            gap:$mini-padding-between;
            &::after {
                background-repeat: no-repeat;
                width: 201px;
                height: 16px;
                @media (max-width:360px) {
                    content: none;
                }
            }
        }
    }
}
