footer.footer {
    padding: $margin-default 0 0 0;
    // background: $colorblack;
    @media (max-width:768px) {
        padding: $default-padding-mobile 0 0 0;
    }
    .navs-footer {
        display: flex;
        
        @media (max-width:768px) {
            flex-direction: column;
            gap:$margin-default-mobile;
        }
        nav {
            width: 33.3333333%;
            @media (max-width:768px) {
                width: 100%;
            }
            h4 {
                font-size: 30px;
                margin-bottom: $margin-default;
            }
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    a {
                        padding: 10px 0;
                        display: block;
                    }
                }
            }
        }
    }

    .footer-bottom {
        border-top: 1px solid $color-border-dark;
        padding: $inside-block-padding 0;
        margin-top: $inside-block-padding;
        padding-bottom: $default-padding-mobile;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
                display: flex;
                align-items: center;
                gap:$mini-padding-between;
            }
            a {
                font-size: 16px;
                color: lighten($colordark,20);
            }
        }
    }
}

.lang {
    position: relative;
    &-selected {
        display: flex;
        align-items: center;
        cursor: pointer;
        &::after {
            background-position: center;
            background-repeat: no-repeat;
            width: 10px;
            height: 10px;
            margin-left: 10px;
        }
        &__text {
            margin-left: 20px;
        }

        &:hover {
            opacity: 0.7;
        }
    }

   &-dropup {
        position: absolute;
        top: inherit !important;
        bottom: 100%;
        background: $colorblack !important;
        padding: 0px !important;
        margin-top: 0 !important;
        width: 100%;
        margin-bottom: 20px;
        &__item {
            display: block;
            padding: 10px 20px;
            border-bottom: 1px solid $colordark;
            cursor: pointer;
            &:hover {
                background: lighten($colorblack, 20);
            }
            &.active {
                color:lighten($colorblack, 20);
                cursor: default;
                &:hover {
                    background: transparent;
                }
            }
        }
    }
}