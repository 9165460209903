.preloader {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: $colorblack;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	
	animation: loader 1s ease-out 1.5s forwards;
	&__text {
		font-size: 8vw;
		display: flex;
		gap:40px;
		@media (max-width:768px) {
			font-size: 40px;
		}
		&-word {
			display: flex;
			gap:5px;
			overflow: hidden;
		}
		&-latter {
			transform: translate(0, 100px);
			opacity: 0;
		}
	}
}

@keyframes loader {
	100% {
		transform: translateY(-100%);
	}
}