*,
*::before,
*::after {
	box-sizing: border-box;
}

/////////////// FLEX ////////////
/// 

.flex {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}
.--just-space {
	justify-content: space-between;
}

.--just-center {
	justify-content: center;
}

.--just-end {
	justify-content: flex-end;
}

.--align-str {
	align-items: stretch;
}

.--align-center {
	align-items: center;
}

.--align-end {
	align-items: flex-end;
}

.--dir-col {
	flex-direction: column;
}

.text-align-center {
	text-align: center;
}
.text-align-left {
	text-align: left;
}
.text-align-right {
	text-align: right;
}

.zindex {
	position: relative;
	z-index: 10;
}

.gap-mini {
	gap: $mini-padding-between;
}
.gap-bigmini {
	gap: $inside-header-padding;
}
.gap-middle {
	gap: $inside-block-padding;
}
.gap-large {
	gap: $margin-default;
}

html{
	font-size: 1.4vw;
}

html,
body {
	min-height: 100%;
	position: relative;
}
body{
	font-size: 1.4vw;
	background: $colordark;
	font-family: $font-family;
	overflow: hidden;
	@media (max-width:768px) {
		font-size: 16px;
	}
}
.scrollable-area {
	// overflow-y: scroll;
	width: 100%;
	height: calc(100vh);
	// margin-top: 84px;
}
//////// CONTAINER /////////
/// 

.container {
	@include container;
	@media screen and (max-width:768px) {
		@include container-mobile;
	}
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-title;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}
h1 {
	font-size: 7vw;
	@media screen and (max-width:768px) {
		font-size: 60px;
	}
	@media screen and (max-width:575px) {
		font-size: 50px;
	}
	@media (max-width:360px) {
		font-size: 35px;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
	}
}
h2 {
	font-size: 6.5vw;
	@media screen and (max-width:768px) {
		font-size: 50px;
	}
	@media screen and (max-width:575px) {
		font-size: 40px;
	}
	@media screen and (max-width:360px) {
		font-size: 30px;
	}
}
h3 {
	font-size: 5.5vw;
	@media screen and (max-width:768px) {
		font-size: 40px;
	}
	@media screen and (max-width:575px) {
		font-size: 30px;
	}
	@media screen and (max-width:360px) {
		font-size: 20px;
	}
}
h4 {
	font-size: 5vw;
	@media screen and (max-width:768px) {
		font-size: 35px;
	}
	@media screen and (max-width:575px) {
		font-size: 28px;
	}
	@media screen and (max-width:360px) {
		font-size: 18px;
	}
}
a {
	color: $colorprimary-contrast;
	text-decoration: none;
	&:hover {
		color: $colorprimary;
	}
}
p {
	line-height: 140%;
}


.icon {
	background-position: center;
	background-size: contain;
    background-repeat: no-repeat;
	width: 20px;
	height: 20px;
}


.bg {
	background: $colordark;
	color: $colordark-contrast;
}
.shadow-min {
	@include shadow-min;
}

.light {
	&.bg, .bg {
		background: $colordark-contrast;
		color: $colordark;
	}
	.shadow-min {
		@include shadow-min-light;
	}
}

.buttons {
	display: flex;
	align-items: center;
	gap:$mini-padding-between;
}

.btn {
	display: inline-block;
	padding:$mini-padding-between $inside-block-padding;
	border-radius: $border-radius;
	text-decoration: none;
	color: $colordark-contrast;
	font-size: 1.4vw;

	font-weight: bold;
	font-family: $font-family-title;
	@media screen and (max-width:768px) {
		font-size: 16px;
		padding:$inside-header-padding-mobile $inside-block-padding-mobile;
		border-radius: $border-radius-mobile;
	}
}

.btn-default {
	background: $colorprimary;
	color: $colorprimary-contrast;
	@include btn_anim;
}

.button_hover {
	overflow: hidden;
	position: relative;
	display: inline-block;
	border-radius:$border-radius;
	z-index:1;
	&.white {
		.button_hover_inner {
			background:$colordark-contrast;
			color: $colordark;
		}
		&:hover {
			.button_hover_inner {
				color: $colordark-contrast;
			}
		}
	}
	&_inner{
		display: inline-block;
		background:$colorprimary;
		color: $colorprimary-contrast;
		text-align: center;
		border-radius:$border-radius;
		transition: 400ms;
		text-decoration: none;
		padding:$mini-padding-between $inside-block-padding;
		z-index: 10;
		font-size: 1.4vw;

		font-weight: bold;
		font-family: $font-family-title;
		&:hover {
			color: $colorprimary-contrast;
		}
		@media screen and (max-width:768px) {
			font-size: 16px;
			padding:$inside-header-padding-mobile $inside-block-padding-mobile;
			border-radius: $border-radius-mobile;
		}
	}
	.su_button_circle{
		background-color: red;
		border-radius: 100%;
		position: absolute;
		left:0;
		top:0;
		width: 0px;
		height: 0px;
		margin-left: 0px;
		margin-top: 0px;
		pointer-events: none;
		animation-timing-function: ease-in-out;
	}
	.button_text_container{
		position:relative;
		z-index: 10;
	 }
}
.explode-circle {
	animation: explode 0.5s forwards;
}
 
.desplode-circle{
	animation: desplode 0.5s forwards;
}

@keyframes explode {
	0% {
		width: 0px;
		height: 0px;
		margin-left: 0px;
		margin-top: 0px;
		background-color: rgba(42, 53, 80,0.2);
	}
	100% {
		width: 500px;
		height: 500px;
		margin-left: -250px;
		margin-top: -250px;
		background-color: rgba(65, 72, 255,0.8);
	}
}
  
@keyframes desplode {
	0% {
		width: 500px;
		height: 500px;
		margin-left: -250px;
		margin-top: -250px;
		background-color:rgba(65, 72, 255,0.8);
	}
	100% {
		width: 0px;
		height: 0px;
		margin-left: 0px;
		margin-top: 0px;
		background-color: rgba(129, 80, 108,0.6);
	}
  }


  
.border-device {
	border: 10px solid #4A4F57;
	border-radius: $border-radius;
	transition: border 1s ease, border-radius 1s ease;
	overflow: hidden;
}

.fade {
	opacity: 0;
	transition: all 0.7s ease;
}
.visible {
	opacity: 1;
	transition: all 1s ease;
}

img {
	width: 100%;
}


.bg-blue {
	background: $colorprimary;
	position: fixed;
	z-index: -1;

	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
}

.bg-black {
	background: $colorblack;
	position: absolute;
	z-index: -2;

	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
}

.bg-white {
	background: $colorprimary-contrast;
	position: absolute;
	z-index: -2;

	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
}


.dnone {
	display: none;
}


.posfixed {
	position: fixed;
}
.hidden-x {
	overflow-x: hidden;
}
.scrollbar-track {
	z-index: 999 !important;
	background: rgba(222, 222, 222, 0.15) !important;
}
.scrollbar-track-y {
	width: 6px !important;
}
.scrollbar-thumb {
	width: 6px !important;
}


.dropdown-wrapp {
	position: relative;
	.hidde-content {
		position: absolute;
		top: 100%;
		margin-top: 20px;
		background: $colordark;
		padding: 20px;
		opacity: 0;
		transform: translateY(40px);
		&.view {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

@media (max-width:768px) {
	.desctop {
		display: none !important;
	}
}
@media (min-width:769px) {
	.tablet {
		display: none !important;
	}
}