
.fly-text {
	height: 100vh;
	position: relative;
	z-index: 10;

	overflow: hidden;

	display: flex;
	align-items: center;
	h3 {
		position: absolute;
		transform:translate(100vw, 0);
		// top:50%;
		white-space: nowrap;
		font-size:10vw;
		text-transform: uppercase;
		color: $colordark-contrast;
		margin-top: -10vw;
		&.border-flying-text {
			-webkit-text-fill-color: transparent;
  			-webkit-text-stroke: 1px;
			//   top:calc(50% - 138px);
			margin-top: 10vw;
			transform: translate(-100%, 0);
		}
	}
}