.main-tool-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    padding: $inside-header-padding 0;
    z-index: 12;
    transition: all .3s ease-in-out;
    &--scrolled {
        padding: 10px 0;
        transition: all .3s ease-in-out;
    }
    .logo {
        min-width: 143px;
        width: 143px;
        height: 24px;
        overflow: hidden;
        &::before {
            width: 143px;
            height: 24px;
            background-repeat: no-repeat;
            display: block;
        }
        @media (max-width:768px) {
            width: 50px;
            min-width: 50px;
        }
    }
    .btn {
        padding:15px 30px;
        @media (max-width:768px) {
            padding: 10px 20px;
        }
    }
    .button_hover {
        &_inner{
            padding:15px 30px;
            @media (max-width:768px) {
                padding: 10px 20px;
            }
        }
    }
    @media screen and (max-width:768px) {
        padding: $inside-header-padding-mobile 0;
    }
}