// Background mixins
@mixin bg-contain ($size:"contain", $position: "center") {
	background-size: #{$size};
	background-position: #{$position};
	background-repeat: no-repeat;
}
@mixin bg-cover ($size:"cover", $position: "center") {
	background-size: #{$size};
	background-position: #{$position};
	background-repeat: no-repeat;
}

// button mixins
@mixin btn_anim ($scaleMax:1.05, $scaleMin:0.95) {
	transform-origin: center center;
	transition: all ease-out 240ms;

	&:hover {
		transform: scale($scaleMax);
	}

	&:focus{
		outline: transparent;
	}

	&:focus-visible {
		transform: scale($scaleMax) translateY(-5%);
	}

	&:active {
		transform: scale($scaleMin);
	}
}

// button clean
@mixin no-btn ($display:"inline-block") {
	padding: 0;
	margin: 0;
	border: 0;
	background-color: transparent;
	border-radius: 0;
	cursor: pointer;
	appearance: none;
	display: #{$display};
}

// Container mixin 
@mixin container ($max-width:"120rem",$padding:$inside-block-padding){
	width: 100%;
	max-width: #{$max-width};
	padding: 0 #{$padding};
	margin: 0 auto;
}
@mixin container-mobile ($max-width:"100%",$padding:$inside-block-padding-mobile){
	width: 100%;
	max-width: #{$max-width};
	padding: 0 #{$padding};
	margin: 0 auto;
}

/// center position
@mixin centerPosition (){
	left: 50% !important;
	top: 50% !important;
	transform: translateX(-50%) translateY(-50%);
}



// box shadows

@mixin shadow-min ($size:"0 5px 15px", $color: "rgb(0, 0, 0, .3)") {
	box-shadow: #{$size} #{$color} ;
}
@mixin shadow-min-light ($size:"0 5px 15px", $color: "rgb(195, 195, 195, .3)") {
	box-shadow: #{$size} #{$color} ;
}