.faq-wrapper {
	padding-top: 50px; // AH
	
	background: $colorblack;
	padding: 180px 0;
	@media (max-width:768px) {
		padding-bottom: $default-padding-mobile;
	}
}

#faq {
	h3 {
		margin-bottom: $margin-default;
	}
}
.faq-item {
	padding: $inside-block-padding 0;
	border-bottom: 1px solid $colordark-contrast;
	transition: all 0.5s ease;
	@media (max-width:768px) {
		padding: $inside-block-padding-mobile 0;
	}
	&:first-child {
		border-top: 1px solid $colordark-contrast;
	}
	&:hover {
		background-color: lighten($colordark, 5);
		transition: all 0.5s ease;
	}
	&.is-active {
		.arrowDown {
			transform: rotate(-180deg);
			transition: transform 0.5s ease;
		}
		.hidden {
			margin-top: $inside-block-padding;
			transition: all 0.5s ease;
		}
	}
	.title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		.arrowDown {
			width: 30px;
			height: 17px;
			background-repeat: no-repeat;
			background-position: center;
			margin-right: $inside-header-padding;
			transition: transform 0.5s ease;
			@media (max-width:768px) {
				width: 16px;
			}
		}
	}
	.hidden {
		position: relative;
		max-height: 0;
		overflow: hidden;
		transition: .2s ease all;
		
	}
}