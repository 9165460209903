
.crm {
	position: relative;
	width: 100%;
	overflow: hidden;
	height: 100vh;
	pointer-events: none;
	z-index: 1111;
	&-slider {
		position: relative;
		padding: $inside-block-padding;
		min-height: 100vh;
		padding-top: 154px;
		display: flex;
		justify-content: space-around;
		align-items: center;

		z-index: 11;
		@media (max-width:768px) {
			padding: $inside-block-padding-mobile;
			min-height: auto;
			padding-top: 100px;
			flex-direction: column-reverse;
			justify-content: flex-end;
		}
		.text-wrap {
			position: relative;
			overflow: hidden;
			min-width: 30%;
			max-width: 30%;
			height: 80vh;
			@media (max-width:768px) {
				height: auto;
				min-height: 480px;
				min-width: 100%;
				max-width: 100%;
			}
			@media (max-width:360px) {
				min-height: 500px;
			}
			.slide-text-crm {
				position: absolute;
				left: 0%;
				top: 0%;
				right: 0%;
				bottom: 0%;
				z-index: 1;
				width: 100%;
				height: 100%;
				padding-right: $margin-default;
				transform:translateY(100%);
				opacity: 0;
				
				h3 {
					margin-bottom: $inside-block-padding;
				}
				ul {
					margin-top: $inside-block-padding;
					line-height: 140%;
				}
				@media (max-width:360px) {
					font-size: 16px;
				}
			}
		}
		.slide-wrap {
			width: 100%;
			position: relative;
			overflow: hidden;
			height: 80vh;
			@media (max-width:768px) {
				height: auto;
				min-height: 240px;
			}
			@media (max-width:360px) {
				display: none;
			}
			.slide-crm {
				position: absolute;
				left: 0%;
				top: 0%;
				right: 0%;
				bottom: 0%;
				z-index: 1;
				width: 100%;
				height: 100%;
				overflow: hidden;
				img {
					border-radius: $border-radius;
				}
			}
		}

	}


	&-text {
		width: 100%;
		height: 100vh;
		position: absolute;
		// .substrate-2 {
		// 	content: "";
		// 	position: absolute;
		// 	left: 0;
		// 	top: 0;
		// 	width: 100%;
		// 	height: 100%;
		// 	background: $colorprimary-contrast;
		// 	z-index: 1;
		// }
		.zoom {
			transform: scale(60);
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			opacity: 0;
		}
		.overlay-crm {
			position: absolute;
			left: 0;
			top: 0;
			transform: scale(60);
			width: 100%;
			height: 100%;
			background: $colorprimary-contrast;
			z-index: 1;
		}
	}
}
