$colordark:#1B1D20;
$colordark-contrast:#f1f1f1;

$colorprimary:#418DFF;
$colorprimary-contrast:#f1f1f1;

$colorblack:#101214;

$color-border-dark:#3D3D3D;

$border-radius:3vw;
$border-radius-mobile:40px;

$default-padding: 8vw;
$margin-default:4vw;
$inside-block-padding:2vw;
$inside-header-padding:0.8vw;
$mini-padding-between:0.8vw;

$default-padding-mobile: 100px;
$margin-default-mobile:30px;
$inside-block-padding-mobile:20px;
$inside-header-padding-mobile:15px;
$mini-padding-between-mobile:10px;

$font-family-title:'Raleway', sans-serif;
$font-family:'Roboto', sans-serif;

$font-size:2vw;
$font-size-mobile:18px;