.screen {
    padding:0 $inside-block-padding $default-padding;
    position: relative;
    z-index: 10;
    overflow: hidden;
    @media (max-width:768px) {
        padding:0 $inside-block-padding-mobile $default-padding-mobile;
    }
    .blog-zoom-out {
        margin-top: 0px;
        position: relative;
        -webkit-perspective: 1000;
        perspective: 1000;
        transform: scale(1.1);
        overflow: hidden;

        border:0 solid lighten($colordark, 20);
        img {
            width: 100%;
            display: block;
        }
    }
    .text {
        padding-top: 180px;
        width: 50%;

        font-size: $font-size;
        @media (max-width:768px) {
            font-size: $font-size-mobile;
            width: 80%;
        }
    }
}

#title-screen {
    margin-bottom: calc($margin-default * 3);
	position: relative;
	padding:0 $inside-block-padding;
    font-size: 5.5vw;
	white-space: normal;
	opacity: 0;
    @media (max-width:768px) {
        font-size: 40px;
    }
}
