.promo {
    padding: $default-padding $inside-block-padding;
    position: relative;
    z-index: 10;
    overflow: hidden;
    .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    @media screen and (max-width:768px) {
        padding: $default-padding-mobile $inside-block-padding-mobile;
    }
    &-inside {
        display: flex;
        align-items: flex-end;
        @media screen and (max-width:768px) {
            flex-direction: column;
            align-items: start;
            margin-top: 100px;
        }
    }
    h1 {
        width: 60%;
        font-size: 6vw;
        @media screen and (max-width:768px) {
            width: 80%;
            font-size: 70px;
            margin-bottom: $margin-default-mobile;
        }
        @media screen and (max-width:575px) {
            width: 100%;
            font-size: 45px;
        }
        @media screen and (max-width:360px) {
            font-size: 35px;
        }
    }
    .action {
        display: flex;
        flex-direction: column;
        gap:$inside-block-padding;
        align-items: end;
        font-size: $font-size;
        width: 40%;
        position: relative;
       
        &::before {
            content: "";
            position: absolute;
            bottom: 30px;
            left: 0;
            width: calc(100% - 244px);
            height: 2px;
            background: $color-border-dark;
        }

        @media screen and (max-width:768px) {
            width: 100%;
            align-items: flex-start;
            font-size: $font-size-mobile;
            gap:$margin-default-mobile;
            &::before {
                content: none;
            }
        }
    }

}
.blog-zoom-out {
    position: relative;
    margin-top: 120px;
    -webkit-perspective: 1000;
    perspective: 1000;
    transform: scale(1.1);
    overflow: hidden;
    border:0 solid lighten($colordark, 20);
    img {
        width: 100%;
        display: block;
    }

    @media screen and (max-width:768px) {
        margin-top: 90px;
    }
}

