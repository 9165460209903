.services {
	padding-top: $default-padding;
	background-color: $colorblack;
	@media (max-width:768px) {
		padding: $default-padding-mobile 0;
	}
	&-slide {
		margin-top: $default-padding;
		min-height: 100vh;
		position: relative;
		padding:0 $inside-block-padding;
		display: flex;
		align-items: center;
		&__title {
			font-size: 7vw;
			margin-bottom: $margin-default;
			
			overflow: hidden;
			width: 100%;

			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
			@media (max-width:768px) {
				font-size: 45px;
			}
		}
		&__text {
			font-size: 3vw;
			margin-bottom: 30px;
			width: 100%;
			@media (max-width:768px) {
				font-size: $font-size-mobile;
				margin-bottom: $mini-padding-between-mobile;
			}
		}

		@media (max-width:768px) {
			padding:0 $inside-block-padding-mobile 50px $inside-block-padding-mobile;
			padding-top: 40vh;
		}
	}
	.button_hover {
		margin-top: $margin-default;
	}
}
.split-line {
	overflow: hidden;
}
.fadeUp {
	opacity: 0;
	transform: translateY(100px);
}